@import "bigio-ui-kit/variables";
@import "bigio-ui-kit/mixins";

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url("../fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-ExtraBold";
  src: url("../fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "PlusJakartaSans-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

.text-disabled {
  background-color: #5a5a5a33;
  border-radius: 4px;
}

.text-extra-bold {
  font-family: "PlusJakartaSans-ExtraBold";
}

.text-bold {
  font-family: "PlusJakartaSans-Bold";
}

.text-medium {
  font-family: "PlusJakartaSans-Medium";
}

.super-title {
  font-size: 24px;
}

.title {
  font-size: 18px;
}

.normal-title {
  font-size: 14px;
}

.subtitle {
  font-size: 14px;
  opacity: 0.48;
}

.horizontal-line {
  border-bottom: solid 1px #ffffff4a;
  width: 50%;
  margin-left: 25%;
}

.show {
  display: block;
}

.hide {
  display: none;
}

a {
  color: $base-color-primary;
  &:hover {
    color: #0084a5;
    text-decoration: none;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pd-10 {
  padding: 10px;
}

.pd-20 {
  padding: 20px;
}

.pd-30 {
  padding: 30px;
}

.pd-40 {
  padding: 40px;
}

.pd-50 {
  padding: 50px;
}

// shadding
.card.shadow {
  box-shadow: 2px 9px 16px -3px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 2px 9px 16px -3px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 2px 9px 16px -3px rgba(0, 0, 0, 0.41);
}

.sidebar {
  display: flex;
  flex-flow: column;
  grid-area: 1 / 1 / 3 / 2;
  height: max-content;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 350px;
  width: max-content;
  padding: 15px 10px;
  position: relative;
  z-index: 1001;
  background-color: $base-color-primary;
  color: $base-color-title;
  .sidebar-title {
    font-size: 24px;
    font-weight: 700;
    color: $base-color-title;
    cursor: pointer;
    min-height: 100px;
  }
  .sidebar-container {
    padding: 10px 10px;
    margin: 10px 20px;
  }
  .sidebar-item-wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  .nav-item {
    padding: "20px 15px";
    &:hover {
      cursor: pointer;
    }
    .title {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      color: $base-color-title;
      padding: 0 40px;
    }
    .title:hover {
      color: #ffffff;
    }
    .nav-active {
      color: $base-color-primary;
    }
  }
  .active .title,
  .active .sidebar-icon {
    color: #ffffff;
  }
  :hover + {
    .hover-show {
      display: block;
    }
  }
}

.icon-modal {
  color: #a5a5c0;
  font-size: 45px;
  margin-top: 5px;
  margin-right: 29px;
}

.active .sidebar-container {
  background-color: $base-color-title;
  border-radius: 8px;
  color: white;
}

.sidebar-full {
  @media (max-width: 920px) {
    position: fixed;
    transform: translate3d(-108px, 0, 0);
  }
}

.sidebar-mini {
  @media (max-width: 920px) {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0) !important;
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  color: $base-color-title;
}

.sidebar-item:hover,
.sidebar-item:hover .sidebar-icon,
.sidebar-icon:hover + .title {
  color: white !important;
}

.sidebar-icon {
  color: $base-color-title;
}

.backdrop {
  position: fixed;
  height: max-content;
  min-height: 100vh;
  max-height: 100%;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s linear;
}

.back-show {
  top: 0;
  left: 108px;
  opacity: 0.4;
  pointer-events: all;
}

.hover-show {
  display: none;
}

.navbar-container {
  grid-area: 1 / 1 / 3 / 2;
  height: max-content;
  min-height: 100vh;
  max-height: 100%;
  background-color: $base-color-primary;
  position: fixed;
  z-index: 999;
}

.navbar-container:hover + .header-top + .main-top {
  @media (max-width: 920px) {
    margin-left: 0;
  }
}

.header-top {
  z-index: 99;
}

.header-top,
.main-top {
  padding-left: 108px;
  max-width: 100vw;
  @media (max-width: 920px) {
    padding-left: 0;
  }
}

.navbar-container:hover .hover-show,
.sidebar-title:hover .hover-show {
  display: block;
  @media (max-width: 920px) {
    display: none;
  }
}

.navbar-container:hover .horizontal-line {
  border-bottom: solid 1px #ffffff4a;
  width: 80%;
  margin-left: 10%;
}

.container.homepage {
  margin-top: 250px;
  label {
    color: $text-dark;
    font-size: 4rem;
    font-weight: 800;
  }
  .author {
    color: $base-color-primary;
  }
  .btn {
    font-size: 20px;
    font-weight: 500;
    padding: 15px 30px;
  }
  .btn-primary {
    border: none;
    background-color: $base-color-primary;
  }
  .btn-secondary {
    color: $base-color-secondary;
    border: solid 1px $base-color-secondary;
    background-color: white;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 0 0px 1000px #fff inset; /* Firefox 3.5 - 3.6 */
  box-shadow: 0 0 0px 1000px #fff inset; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Leaflet-draw Customize

.leaflet-draw-draw-polygon,
.leaflet-draw-draw-polyline,
.leaflet-draw-draw-marker,
.leaflet-draw-draw-rectangle,
.leaflet-draw-draw-circle,
.leaflet-draw-draw-circlemarker {
  display: none !important;
}

.marker-wrapper {
  img {
    width: 15px !important;
  }
}

// LOADER
.loading {
  font-size: 84px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-align: center;
  span {
    display: inline-block;
    margin: 0 -0.05em;
  }
}

/* code #1 */
.loading01 {
  span {
    animation: loading01 1.4s infinite alternate;
    @for $i from 0 through 6 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}
@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-modal {
  position: absolute;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .loading-content {
    position: relative;
    top: 50%;
  }
  .message {
    margin-top: 20px;
    color: #fff;
  }
}

// FORM

.input-title {
  border: solid 1px #b8b8d6;
  border-radius: 6px;
  padding: 0px 10px;
}

.form-group {
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .input-group {
    .input-group-prepend {
      width: 100%;
      .input-group-text {
        font-size: 18px;
        background: none;
        padding: 10px 15px;
        border-right: none;
        border-radius: 3px;
        border-color: $base-color-secondary;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .MuiSvgIcon-root {
          font-size: 18px;
        }
        ~ input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .input-group-prepend.d-flex.flex-row-reverse {
      input {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 0;
      }
      .input-group-text.input-group-invoice {
        font-size: 16px;
        padding-right: 2px;
        border-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  input {
    border-radius: 3px;
    padding: 10px 15px;
    border: 1px solid $base-color-secondary;
    &:focus {
      -webkit-transition: 0.3s;
      transition: 0.3s;
      outline: none;
      border: 1px solid $base-color-primary;
      + .input-group-text.input-group-invoice {
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border: 1px solid $base-color-primary;
        border-right: none;
      }
    }
    &:valid {
      background-color: #fff;
    }
  }
}

// BREADCRUM
.breadcrumb {
  font-weight: 500;
  background-color: transparent;
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    font-weight: 800;
  }
  .breadcrumb-item.active {
    a {
      color: #6c757d;
    }
  }
}

// CHART
.canvas-container {
  width: auto;
}

.button:hover {
  justify-content: center;
}
.rounded-button {
  border-radius: 30px !important;
  font-size: 14px;
}

.clickable {
  cursor: pointer;
}

.left-icon {
  margin-right: 13px;
}

.right-icon {
  margin-left: 13px;
}

.title-icon {
  padding: 5px 0 45px;
  color: #000000;
  cursor: pointer;
  font-size: 30px;
}

.icon-only {
}

.btn,
.button {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 1.55rem;
  @media (max-width: 920px) {
    justify-content: center;
  }
}

//button

.button {
  font-size: 14px;
  border-radius: 5px;
  padding: 14px 24px;
  // border: none;
  display: inline-flex;
  justify-content: center;
  color: #fff;
  min-width: 95px;
}

.btn-lg {
  padding: 0.875rem 1.55rem;
}

.md {
  min-width: 64px;
  border-radius: 5px;
  height: 40px;
  padding: 0 24px;
  border: none;
  margin-right: 5px;
}
.lg {
  min-width: 64px;
  border-radius: 5px;
  height: 48px;
  padding: 0 32px;
  border: none;
  margin-right: 5px;
}
.disabled {
  background-color: #dedede !important;
  border-color: #dedede !important;
  box-shadow: none !important;
}
.disabled:hover {
  background-color: red !important;
  border-color: #dedede !important;
  box-shadow: none !important;
}

.button-default {
  background-color: $base-color-primary !important;
  border-color: $base-color-primary !important;
  color: white;
  .active {
    background-color: $base-color-primary;
    border-color: $base-color-primary;
  }
}
.button-default:hover:not(.disabled) {
  background-color: $base-color-primary-hover !important;
  border-color: $base-color-primary-hover !important;
  box-shadow: 0 2px 6px $base-color-primary-hover !important;
}

.button-default:hover {
  background-color: $base-color-primary !important;
  border-color: $base-color-primary !important;
  opacity: 0.65;
}

.button-negative {
  background-color: $base-color-danger !important;
  border-color: $base-color-danger !important;
  color: #fff;
  .active {
    background-color: #d53a34 !important;
    border-color: #d53a34 !important;
  }
}
.button-negative:hover:not(.disabled) {
  background-color: #d10d0d80 !important;
  border-color: #d10d0d80 !important;
  box-shadow: 0 2px 6px #d10d0d80 !important;
}

.button-affirmative {
  background-color: #00cf89 !important;
  border-color: #00cf89 !important;
  color: #fff;
  .active {
    background-color: #00ba7b !important;
    border-color: #00ba7b !important;
  }
}
.button-affirmative:hover:not(.disabled) {
  background-color: #00cf8980 !important;
  border-color: #00cf8980 !important;
  box-shadow: 0 2px 6px #00cf8980 !important;
}

.button-light {
  background-color: #fff !important;
  border-color: #e7e7e7 !important;
  color: rgb(28, 28, 28);
  .active {
    background-color: #ffffff !important;
    border-color: #d8d8d8 !important;
    color: #000000;
  }
}
.button-light:hover:not(.disabled) {
  background-color: #fff !important;
  border-color: #d8d8d8 !important;
  box-shadow: 0 2px 6px #d8d8d8 !important;
  color: #000000;
}
.button-light:disabled {
  color: #000000 !important;
}
.button-light:hover,
.button-light:active,
.button-light:focus {
  background-color: #fff !important;
  border-color: #e7e7e7 !important;
  color: #000000;
  box-sizing: unset;
}

.btn-icon {
  font-size: calc(24em / 14);
}

.btn-icon-right {
  margin-left: 10px;
}

.btn-icon-left {
  margin-right: 10px;
}

//notification
.notification {
  width: 400px;
  height: 438px;
  border-radius: 0.25rem;
  padding: 19px 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.notification-header {
  margin-bottom: 19px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.notification-body {
  height: 320px;
}
h6 {
  margin-bottom: 3px !important;
}
.align-right {
  text-align: right;
}
.notification-icon {
  height: 45px;
  border-radius: 90px;
  padding: 10px;
  color: white;
  margin: auto;
  width: 45px;
}
.notification-content {
  display: grid;
  border-top: 1px solid #ececec;
  grid-template-columns: 17% 75% 5%;
  padding: 18px 0;
}
.notification-time {
  margin: auto;
  font-size: 10px;
}
.notification-footer {
  text-align: center;
  color: $base-color-primary;
}
.blue-text {
  color: $base-color-primary;
}
.notification-span {
  font-size: 11.5px;
}

.danger {
  background-color: $base-color-danger !important;
}

.warning {
  background-color: $base-color-warning !important;
}

.grey {
  background-color: $base-color-grey !important;
}

.primary {
  background-color: $base-color-primary !important;
  border-color: $base-color-primary;
}

.primary:hover {
  background-color: $base-color-primary-hover;
  border-color: $base-color-primary-hover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.primary:focus,
.primary:active {
  background-color: $base-color-primary-hover !important;
  border-color: $base-color-primary-hover !important;
}

.primary.disabled,
.primary.disabled:hover,
.success.disabled:hover {
  background-color: $base-color-disabled !important;
  border-color: $base-color-disabled !important;
  color: #ffffff;
}

.success {
  background-color: #00cf89;
  border-color: #00cf89;
}

.secondary {
  background-color: #ffffff;
  border: solid 1px $base-color-secondary;
  color: #3e3e3e;
}

.secondary:hover {
  background-color: #ffffff;
  border: solid 1px $base-color-secondary;
  color: #3e3e3e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.secondary:focus,
.secondary:active {
  background-color: $base-color-secondary !important;
  border-color: $base-color-secondary !important;
}

.search-toggle,
.search-toggle:hover,
.search-toggle:focus,
.search-toggle:active {
  background-color: transparent;
  border: none;
  color: #3e3e3e;
  padding-right: 0;
  padding-left: 0;
}

.search-container {
  position: relative;
  margin-right: 0;
  // width: 0px;
}

.input-search-mini {
  padding: 11px 20px;
  border: 1px solid hsl(240, 27%, 92%);
  background: #fff;
  border-radius: 5px;
  outline: none !important;
  width: 100%;
}

.input-search {
  transition: width 0.15s;
  width: 10px;
}

.input-search,
.input-search:hover,
.input-search:focus {
  visibility: hidden;
  padding: 11px 20px;
  border: 1px solid #e5e5f0;
  box-sizing: unset;
  background: #fff;
  border-radius: 5px;
  outline: none !important;
  overflow-x: hidden;
  position: absolute;
  right: 0;
}

.input-search::placeholder {
  color: #b8b8d6;
  font-size: 14px;
}

.input-search.show {
  width: 180px;
  visibility: visible;
  @media (max-width: 920px) {
    width: 91% !important;
  }
  @media (max-width: 500px) {
    width: 85% !important;
  }
}

.input-search.default {
  visibility: visible;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0;
  padding: 11px 11px 11px 50px;
  font-size: 16px;
}

.red-bg {
  background-color: red;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #5a5a5a;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.text-muted {
  color: #98a6ad !important;
}

// Flex container
.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.flex-6 {
  width: 50%;
}

.content-wrapper {
  margin-top: 100px;
  padding: 50px 180px;
}

.content-container {
  padding: 50px 40px;
}

//  Tab
.tab-menu {
  @media (max-width: 920px) {
    margin-bottom: 10px;
    div {
      margin-bottom: 2px;
    }
  }
}

.MuiTab-root {
  padding: 6px 10px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 60px;
  box-sizing: border-box;
  min-height: 60px !important;
  text-align: center;
  flex-shrink: 0;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: capitalize !important;
  margin-right: 40px !important;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 21px !important;
  font-family: "PlusJakartaSans-Bold" !important;
  font-size: 900;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #000000 !important;
  font-family: "PlusJakartaSans-Bold" !important;
  font-size: 900;
}

.PrivateTabIndicator-colorPrimary-2 {
  background-color: $base-color-primary !important;
  height: 6px !important;
}

.PrivateTabIndicator-root-1 {
  width: 100%;
  bottom: 0;
  height: 6px !important;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.PrivateTabIndicator-colorPrimary-6,
.jss2,
.MuiTabs-indicator {
  background-color: $base-color-primary !important;
  height: 6px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 88px !important;
  }
}

// Image
.img {
  width: 100%;
}

.menu-container {
  margin: 0px;
  padding: 0px;
  padding-right: 20px;
  height: max-content;
  min-height: 100vh;
  @media (max-width: 920px) {
    padding: 0;
  }
}

.content-container-side {
  padding: 0px 40px;
  max-width: 100vw;
  @media (max-width: 920px) {
    padding: 10px 35px;
  }
  .overflow {
    overflow-y: none;
    overflow-x: auto;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }
}

.side-menu {
  width: 350px;
  border-right: solid 5px #e5e5f0;
  min-height: 100vh;
  grid-area: 1 / 1 / 3 / 2;
  @media (max-width: 600px) {
    .side-menu {
      width: 100%;
    }
  }
}

@media (max-width: 420px) {
  .side-menu {
    width: 100%;
    min-height: min-content;
    grid-area: 1 / 1 / 3 / 2;
    height: max-content;
  }
}

.side-menu-item {
  border-bottom: solid 1px #e5e5f0;
  margin-top: 0;
  padding: 29px 40px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.side-menu-item:hover,
.side-menu-item-active {
  background-color: #f3f3f8;
}

.side-menu-info {
  margin-right: 50px;
  width: 70%;
}

.side-menu-title {
  font-family: "PlusJakartaSans-Bold";
  font-size: 18px;
}

.side-menu-subtitle {
  font-size: 14px;
  margin-top: 12px;
}

.side-menu-icon {
  text-align: center;
  align-content: center;
  align-self: center;
}

.side-menu-back-icon {
  float: left;
  padding: 5px 20px 0 0;
  cursor: pointer;
}

.detail-menu-close-icon {
  padding: 10px 10px 3px;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid #e5e5f0;
  border-radius: 50%;
}

.mini-show {
  display: none;
}

.mini-hide {
  display: block;
}

.menu-title {
  font-size: 24px;
  padding: 50px 40px 36px;
}

.MuiInputBase-root {
  border-radius: 8px;
}

.mini-map-wrapper {
  @media (max-width: 920px) {
    height: 100vh !important;
    .header-nav.header-title {
      padding: 0 !important;
      .header-title-container {
        padding: 5px 20px;
      }
    }
  }
}

.main-content-wrapper {
  .header-nav {
    @media (max-width: 920px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
      z-index: 80 !important;
      .header-mini-title {
        background-color: #fff;
      }
    }
  }
  .header-title {
    .menu-right {
      @media (max-width: 920px) {
        padding: 20px 0px;
      }
    }
  }
}

.header-title {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: stretch;
  min-height: max-content;
  .menu-title-icon {
    float: left;
    align-content: center;
    align-self: center;
    padding: 5px 20px 0 0;
  }
  .menu-right {
    float: right;
    text-align: right;
    align-self: right;
    padding: 50px 0px 36px;
    .menu-item {
      display: inline-flex;
    }
  }
  @media (max-width: 500px) {
    .menu-title {
      padding: 20px;
      margin-left: 20px;
    }
    .menu-title-elipsis {
      max-width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    min-height: min-content;
    .menu-title {
      padding: 20px;
      margin-left: 20px;
    }
    .menu-right {
      padding: 20px 30px 20px 5px;
    }
    .menu-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;

      input {
        width: 100%;
      }
      .header-vertical-line {
        display: none;
      }
    }
  }
}

.header-vertical-line {
  border-right: solid 1px $base-color-secondary;
  min-height: 48px;
  min-width: 1px;
  margin: 0 18px;
}

.grid-header {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  height: 100vh;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-template-rows: min-content 1fr;
    height: min-content;
  }
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  .content-wrapper {
    width: 55%;
    margin: 0;
    padding: 70px 175px 20px;
    position: relative;
  }
  .side-login {
    width: 45%;
    background-color: $base-color-primary;
    background-image: url("../images/background/background-login.svg");
  }
  @media (max-width: 920px) {
    .content-wrapper {
      width: 100%;
      margin: 0;
      padding: 60px 40px;
    }
    .side-login {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .flex-container {
    padding: 10px;
    .flex-item {
      width: 100%;
    }
  }

  .title-small-center {
    text-align: center;
  }

  .flex-small-reverse {
    flex-wrap: wrap-reverse;
  }

  .content-wrapper {
    padding: 50px 10px;
  }

  .mini-show {
    display: block;
    .line-buttons {
      box-shadow: none !important;
      .button-link {
        border-radius: 4px !important;
        border: solid 1px #e0e0e0 !important;
      }
    }
  }

  .mini-hide {
    display: none;
  }

  .under-element {
    z-index: -1;
    max-height: 100%;
    overflow-y: hidden;
  }
}

//modal
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  pointer-events: inherit;
}
.modal-header {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  // border-bottom: 1px solid #dee2e6;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
  margin: 0 20px;
  color: #242726;
  font-size: 24px;
}

.modal-content {
  background-color: #fefefe;
  border: none;
  display: block;
  margin: 0 20px;
  width: 530px;
}
@media screen and (max-width: 550px) {
  .modal-content {
    margin: 0 10px;
    display: block;
    width: 470px;
  }
}
@media screen and (max-width: 415px) {
  .modal-content {
    margin: 0 20px;
    display: block;
    width: 355px;
  }
}
@media screen and (max-width: 380px) {
  .modal-content {
    margin: 0 20px;
    display: block;
    width: 325px;
  }
}

.modal-footer {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem;
  // border-top: 1px solid #dee2e6;
  border-top: none;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  flex-direction: row;
  justify-content: flex-end;
}

.empty-state {
  text-align: center;
}
.empty-state-caption {
  margin-top: 20px;
}
.img-empty-state {
  width: 35%;
}

// Pagination
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
}

.page-link {
  position: relative;
  display: block;
  text-align: center;
  margin-left: 8px;
  line-height: 1.25;
  color: #495057;
  background-color: #f7f8f8;
  border: none;
  border-radius: 8px;
  height: 40px;
  min-width: 40px;
}
.page-link:hover {
  z-index: 2;
  color: $base-color-primary;
  text-decoration: none;
  background-color: #f7f8f8;
  border: solid 1px $base-color-primary;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: transparent;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.page-item.active .page-link {
  z-index: 1;
  color: $base-color-primary;
  text-decoration: none;
  background-color: #f7f8f8;
  border: solid 1px $base-color-primary;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.pagination .previous.disabled,
.pagination .previous.disabled:hover,
.pagination .next.disabled,
.pagination .next.disabled:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.pagination .previous .page-link,
.pagination .next .page-link {
  background-color: $base-color-primary !important;
  border-color: $base-color-primary !important;
  color: white;
  box-shadow: none !important;
  padding: 8px 10px !important;
}

.pagination .previous.disabled .page-link,
.pagination .next.disabled .page-link {
  background-color: #a2dbbf !important;
  border-color: #a2dbbf !important;
  color: white;
  box-shadow: none !important;
}

// Scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #c4c4c4;
}

.nav-separator {
  border-left: 1px solid #e3e3e3;
}

.search-container.search-button {
  display: inline-flex;
  align-items: center;
  margin-right: 0;
}

.search-container.search-button-default {
  margin-right: 0;
  height: 47px;
}

.search-ico-left {
  padding: 14px 0px 14px 10px;
}

.close {
  cursor: pointer;
}

.MuiPopover-paper.MuiPaper-rounded {
  border-radius: 5px;
}

.MuiPopover-paper {
  min-width: 100px !important;
  ul {
    padding: 0;
    li {
      margin: 0 15px 0 15px;
      padding: 10px 10px 10px 0px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid rgb(223, 223, 223);
    }
  }
}

.bordered-b {
  border-bottom: solid 1px #e5e5f0;
}

.leaflet-container {
  height: 100%;
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.blinking * {
  animation: fade 0.5s infinite alternate;
}

.aquamarine {
  fill: aquamarine !important;
  color: aquamarine !important;
  background-color: aquamarine !important;
  width: 15px;
  height: 15px;
  * {
    stroke: aquamarine !important;
    fill: aquamarine !important;
    color: aquamarine !important;
    background-color: aquamarine !important;
  }
}

.map-area {
  width: 100%;
  height: 100%;
  background-color: aliceblue;
  position: absolute;
  top: 0;
  z-index: 0;
  overflow: hidden;
}

.map-area-move {
  .leaflet-container {
    cursor: move !important;
  }
}

.map-area-default {
  .leaflet-container {
    cursor: default !important;
  }
}

.nav-with-color {
  background-color: #fff;
  padding-right: 20px !important;
}

.header-nav,
.footer-nav {
  z-index: 99;
}

.suggestion-search {
  position: relative;
  min-width: 330px;
  .input-search.default {
    font-size: 14px !important;
  }
  .suggestion-wrapper {
    z-index: 99;
    border-radius: 8px;
    position: absolute;
    background-color: #fff;
    top: 55px;
    padding: 10px 5px;
    width: 100%;
    .suggestion-not-found {
      list-style-type: none;
      padding: 5px 10px;
      font-size: 12px;
    }
    .suggestion-item {
      list-style-type: none;
      cursor: pointer;
      padding: 5px 10px;
      border-bottom: solid 1px $base-color-secondary;
      font-size: 12px;
    }
    .suggestion-item:hover {
      background-color: $base-color-primary;
      color: #fff;
    }
  }
}

.small-notification {
  margin: 10px 20px;
  font-size: 12px;
  opacity: 0.8;
  width: 100%;
}

.hydraulic-map-menu {
  z-index: 99;
  padding: 10px 40px;
  .hydraulic-menu {
    display: flex;
    justify-content: space-between;
  }
  .hydraulic-information {
    background-color: #fff;
    margin-top: 20px;
    padding: 15px;
    max-width: 330px;
    border-radius: 8px;
    .title-info {
      font-size: 18px;
    }
    .hydraulic-information-detail {
      border-radius: 8px;
      border: solid 2px #5a5a5a1a;
      display: block;
      margin-top: 20px;
    }
  }
}

.hydraulic-popup-wrapper {
  position: absolute;
  min-width: 100%;
  top: 210px;
}

.hydraulic-menu-bar {
  z-index: 99;
  padding: 0px 40px;
  background-color: #fff;
  border-top: solid 1px $base-color-secondary;
  border-bottom: solid 1px $base-color-secondary;
  display: flex;
  align-items: center;
  .hydraulic-icon-first {
    padding: 12px 4px 12px 0px !important;
    .icon-wrapper {
      padding: 2px 4px;
      height: 30px;
    }
  }
  .hydraulic-icon {
    opacity: 0.8;
    padding: 12px 4px;
    cursor: pointer;
    .icon-wrapper {
      padding: 2px 4px;
      height: 30px;
    }
  }
  .hydraulic-icon:hover {
    opacity: 0.9;
  }
  .hydraulic-icon.active {
    opacity: 1 !important;
  }
  .hydraulic-icon.active .icon-wrapper {
    background-color: #04aa5933;
    color: #04aa59;
    border-radius: 4px;
    opacity: 1;
  }
}

.hydraulic-menu-right {
  padding: 30px 10px 0px !important;
}

.hydraulic-nav {
  display: block !important;
  .menu-title {
    padding: 20px 40px 0px;
  }
  .menu-subtitle {
    display: flex;
    font-size: 14px;
    padding: 5px 40px 10px;
    align-items: center;
    .separator {
      font-size: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .subtitle-info {
      opacity: 0.5;
    }
  }
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 520px) {
    padding-right: 28px;
  }
}

.inactive-pointer-evt {
  pointer-events: none;
}

.active-pointer-evt {
  pointer-events: visible;
}

.line-buttons {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;

  .button-link {
    position: relative;
    display: block;
    text-align: center;
    margin-left: 0px;
    line-height: 1.25;
    color: #495057;
    background-color: #ffffff;
    height: 50px;
    min-width: 50px;
    border-style: solid;
    border-color: #e0e0e0;
    border-width: 1px;
    border-right: none;
  }

  .button-item {
    .button-link {
      padding: 0.9rem 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .button-link.lm {
      padding: 0.6rem 1.9rem;
      font-weight: 400;
      font-size: 22px;
    }
    .button-link.active {
      background-color: #f3f3f8;
    }
  }

  .button-item:first-child .button-link {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .button-item:last-child .button-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #e0e0e0;
  }
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  position: relative;

  .main-fill-content {
    flex: 1;
    overflow: auto;
    padding: 0 40px 0 40px;
  }
}

.footer-nav {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.footer-map {
  @media (max-width: 920px) {
    position: fixed;
    bottom: 10px;
    left: 20px;
  }
}

.sub-item {
  border-bottom: solid 1px #e5e5f0;
  margin-top: 0;
  padding: 23px 40px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 3px #b8b8d6;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 3px #b8b8d6;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $base-color-primary;
  border: none;
}

/* When the checkbox is disabled, add a blue background */
.checkbox-container input:disabled ~ .checkmark {
  background-color: #b8b8d6;
  border: none;
  opacity: 0.7;
  cursor: context-menu;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}
.sub-item.active {
  background: #f3f3f8;
}

.wrapper-detail-card {
  border: solid 1px #e5e5f0;
  border-radius: 16px;
  margin: 0 0 20px;
}

.shaded {
  -webkit-box-shadow: 0px 5px 13px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 13px 1px rgba(0, 0, 0, 0.1);
}

.toast-v2 {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #e5e5f0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.toast-v2 .material-icons-round {
  font-size: 100px;
  color: $base-color-primary;
}

.dot {
  width: 4px;
  height: 4px;
  background: #242726;
  border-radius: 50%;
}

.fixed-bottom {
  bottom: 20px;
  @media (max-width: 920px) {
    .button,
    .btn {
      width: max-content;
      justify-content: center;
    }
  }
}

.wrapper-month-picker {
  .MuiFilledInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: none !important;
  }
  .MuiFilledInput-underline::after {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: none !important;
    border-bottom: none;
  }
  .MuiInputBase-root {
    max-width: 366px;
  }
  .MuiInputBase-input {
    border: solid 1px #e5e5f0;
    border-radius: 32px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.wrapper-month-picker-new {
  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-formControl,
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}

.MuiFilledInput-root {
  background-color: transparent !important;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.dashboard-container {
  height: 100%;
  min-height: 100vh;
  .dashboard-left-content {
    border-right: 5px solid #f3f3f8;
  }
  .dashboard-right-content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .production-card {
    .title {
      font-size: 20px;
    }
    .normal-title {
      font-size: 18px;
    }
  }
  .square-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 16px;
    .material-icons {
      color: $base-color-light;
    }
  }
  .square-icon.biru {
    background: $base-color-biru;
  }
  .square-icon.hijau {
    background: $base-color-sucess;
  }
  .square-icon.kuning {
    background: $base-color-warning;
  }
  .square-icon.hitam {
    background: $base-color-tooltip;
  }
  .average-card {
    margin: 16px 0px;
    border-radius: 16px;
    border: 1px solid #e5e5f0;
    .total {
      font-size: 48px;
      font-weight: bold;
      color: $base-color-primary;
    }
  }
  .repair-work-card {
    margin: 16px 0px;
    border-radius: 16px;
    border: 1px solid #e5e5f0;
    .material-icons.hijau {
      color: $base-color-primary;
      font-size: 30px;
    }
  }
  .nrw-card {
    margin: 16px 0px;
    border-radius: 16px;
    border: 1px solid #e5e5f0;
    .material-icons.hijau {
      color: $base-color-primary;
      font-size: 30px;
    }
  }
}

.marker-cluster span {
  font-weight: bold;
  font-size: large;
}

// .marker-title .custom-set{
//   display: flex;
//   flex-direction: column;
//   white-space: nowrap;
// }

.marker-cluster span {
  display: none;
}
