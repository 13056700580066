.table td, .table th {
    background-color: #FFFFFF !important;
    padding: 16px !important;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    font-size: 14px !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6; 
}

.btn-elipsis{
    background-color: transparent;
    font-weight: 800;
}

.label-sign{
    padding: 10px;
    border-radius: 5px;
}

.label-sign.label-sign-primary{
    background-color: #04AA59;
    color: #fff;
}

.label-sign.label-sign-error{
    background-color: #d00000;
    color: #fff;
}