// CARD
.card {
  box-shadow: 0 4px 8px #60617029;
  background-color: #fff;
  border: 1px solid #e5e5f0;
  border-radius: 12px;
  color: #6c7580;
  position: relative;
  margin-bottom: 30px;
  .card-header {
    background-color: inherit;
    color: #4894fe;
    border-bottom: none !important;
    display: flex;
    font-weight: bold;
    line-height: 30px;
    min-height: 70px;
    padding: 32px 32px 5px;
  }
  .card-body {
    color: #3e3e3e;
    padding: 24px 32px;
    background-color: inherit;
  }
  .card-footer {
    padding: 24px 0;
    background-color: inherit;
    border: none;
  }
  .left {
    text-align: left !important;
  }
  .center {
    text-align: center !important;
  }
  .right {
    text-align: right !important;
  }
}

.card-flush {
  padding: 16px 0;
  justify-content: center;
  color: #242726;
  .card-flush-title {
    font-family: "PlusJakartaSans-Bold";
  }
  .card-flush-desc {
    font-weight: 500;
  }
  .card-flush-icon {
    color: #242726;
  }
  .report {
    padding: 0 30px;
    .report-number {
      margin: 0 0 10px;
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
    }
    .report-desc {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .conclusion {
    padding: 0 30px;
    .conclusion-title {
      font-weight: 700;
    }
    .conclusion-desc {
      padding: 14px 0;
      font-weight: 400;
    }
  }
  .right-icon {
    color: #a5a5c0;
  }
}
.orange-indicator {
  margin: 0 30px;
  border-left: 3px solid #fd9517;
}
.red-indicator {
  margin: 0 30px;
  border-left: 3px solid #e01640;
}
.container-pill {
  margin: 0 32px 24px;
}
.pill {
  margin: 0 10px 10px;
  border: 1px solid #e5e5f0;
  border-radius: 24px;
  padding: 10px 30px;
  color: #242726;
}
.bg-green {
  color: #6ac12f;
  border: 1px solid rgba(106, 193, 47, 0.3);
  border-radius: 8px;
  background-color: #f0f9ea;
}
.bg-red {
  color: #e01640;
  border: 1px solid rgba(224, 22, 64, 0.3);
  border-radius: 8px;
  background-color: #f9eaea;
}
.bg-yellow {
  color: #edaa21;
  border: 1px solid rgba(224, 210, 22, 0.3);
  border-radius: 8px;
  background-color: #fdf9df;
}

// Card Diagram
.text-wrapper-card-xl-stretch {
  height: 1033px;
  padding: 426px 5px;
}
.text-wrapper-card-xl {
  height: 900px;
  padding: 426px 5px;
}
.text-wrapper-card-lg-stretch {
  height: 630px;
  padding: 270px 5px;
}
.text-wrapper-card-lg {
  height: 500px;
  padding: 200px 5px;
}
.text-wrapper-card-md-stretch {
  height: 370px;
  padding: 160px 5px;
}
.text-wrapper-card-md {
  height: 370px;
  padding: 130px 5px;
}
.text-wrapper-card-sm {
  height: 233px;
  padding: 80px 5px;
}
.text-wrapper-card-strecth {
  padding: 13px 5px;
}
