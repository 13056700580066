// UI Kit - Q1 2021
// Add "Title-Subtitle" based on design

// Typography - Color
$base-color-primary: #04aa59;
$base-color-secondary: #dedede;
$base-color-grey: #b8b8d6;
$base-color-sucess: #3bc862;
$base-color-info: #04aa594d;
$base-color-warning: #edaa21;
$base-color-danger: #d10d0d;
$base-color-light: #e3eaef;
$base-color-dark: #5a5a5a;
$base-color-title: #ffffff4a;
$base-color-disabled: #04aa5980;
$base-color-primary-hover: #00d66f;
$base-color-primary-seer: #4894fe1a;
$base-color-biru: #216fed;
$base-color-tooltip: rgba(0, 0, 0, 0.76);

// Typography - Text Color
$text-primary: #04aa59;
$text-secondary: #a5a5a5;
$text-success: #00cf89;
$text-info: #04aa594d;
$text-warning: #d10d0d;
$text-light: #dedede;
$text-dark: #5a5a5a;
