.leaflet-div-icon {
    background: transparent;
    border: none;
}

.marker-title{
    width: 200px;
    top: -40px;
    left: -94px;
    position: absolute;
    text-align: center;
    font-size: 15px;
    font-weight: 650;  
}

.marker-title-head{
    width: 200px;
    top: -40px;
    left: -94px;
    position: absolute;
    text-align: center;
    font-size: 15px;
    font-weight: 650;  
}

.marker-person-bottom {
    top: 30px;
    left: -80px;
}

.marker-title-bottom {
    top: 60px;
    left: -80px;
}

.marker-title-right{
    width: 100px;
    top: -5px;
    left: 30px;
    position: absolute;
    text-align: left;
    font-size: 15px;
    font-weight: 600;  
}

.leaflet-container a.leaflet-popup-close-button{
    top: 10px;
    right: 10px;
}

.popup-poly-detail{

    .leaflet-popup-content{
        flex-direction: column;
        margin: 15px;
        width: max-content;
        
    }
}

.popup-marker-detail{
    background-color: #fff;
    min-width: 200px;
    padding: 15px;
    font-size: 14px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 5px 13px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 13px 1px rgba(0, 0, 0, 0.05);
    div:not(.leaflet-popup-content-wrapper){
        display: flex;
    }
    .leaflet-popup-content{
        flex-direction: column;
        margin: 8px 8px;
        // width: max-content;
        
    }
    .leaflet-popup-content-wrapper, .leaflet-popup-tip{
        box-shadow: none;
    }
    .leaflet-popup-tip-container{
        height: 27px;
    }
    .leaflet-popup-tip{
        margin: 6px auto 0;
    }
    .sub-title{
        padding-bottom: 14px;
    }
    div:not(:first-child).bordered{   
        padding: 14px;
    }
    div:not(.tooltip-title).bordered{
        border: 1px solid rgb(221, 221, 221);
    }
    div:not(.tooltip-title):not(:last-child){
        border-bottom: none;
    }
    .tooltip-title{
        font-size: 20px;
        padding-bottom: 10px;
    }
    .top-rounded{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .bottom-rounded {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .wrapper-col{
        div:last-of-type{
            margin-left: auto;
        }
    }
}
  